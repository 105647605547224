
import { Options, Vue } from 'vue-class-component';
import navbar from "@/components/navbar.vue";

@Options({
    components: {
        navbar
    },
})
export default class Layout extends Vue {

    created() {
    }

    get scrollable(){
        return this.$route.meta.scrollable ? "scrollable" : "";
    }

}
